<template>
  <div>
    <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
      </el-form-item>
      <el-form-item>
        <el-select clearable filterable v-model="queryParams.module" placeholder="请选择创意模块" @change="resetPageAndRefresh">
          <el-option v-for="item in moduleOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select clearable filterable v-model="queryParams.status" placeholder="请选择处理状态" @change="resetPageAndRefresh">
          <el-option label="待处理" value="0">待处理</el-option>
          <el-option label="已处理" value="1">已处理</el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select clearable filterable v-model="queryParams.ideaType" placeholder="请选择类型" @change="resetPageAndRefresh">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.content" clearable placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <div v-if="creativeTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="creativeList" :total="creativeTotal" :loading="tLoading" v-if="creativeTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh">
        <el-table-column sortable="custom" prop="extend.moduleName" show-overflow-tooltip label="创意模块">
        </el-table-column>
        <el-table-column sortable="custom" prop="extend.ideaTypName" show-overflow-tooltip label="类型"></el-table-column>
        <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态">
          <template slot-scope="scoped">
            <span v-if="scoped.row.status==0">待处理</span>
            <span v-if="scoped.row.status==1">已处理</span>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="title" show-overflow-tooltip label="标题"></el-table-column>
        <el-table-column sortable="custom" prop="extend.userName" show-overflow-tooltip label="发布人"></el-table-column>
        <el-table-column sortable="custom" prop="extend.schoolName" show-overflow-tooltip label="学校"></el-table-column>
        <el-table-column sortable="custom" prop="extend.mobile" show-overflow-tooltip label="手机号"></el-table-column>
        <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="提交时间"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="dispose(scope.row.id)" icon="el-icon-setting" title="处理"></el-button>
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:cxzj:lead')"  size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="审核"></el-button>
            <el-button  v-if="OA.includes('jcxx:cxzj:del')"  size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>
    <!-- 详情 -->
    <el-dialog title="详情" :visible.sync="bVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="创意模块：" prop="extend.moduleName">
          <span v-text="details.extend.moduleName"></span>
        </el-form-item>
        <el-form-item label="类型：" prop="extend.ideaTypName">
          <span v-text="details.extend.ideaTypName"></span>
        </el-form-item>
        <el-form-item label="标题：" prop="title">
          <span v-text="details.title"></span>
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <span v-text="details.content"></span>
        </el-form-item>
        <el-form-item label="图片：" prop="imgList">
          <span v-for="(item,index) in details.imgList" :key="index">
            <el-image style="width: 100px; height: 100px" :src="item"></el-image>
          </span>
        </el-form-item>
        <el-form-item label="发布人：" prop="extend.userName">
          <span v-text="details.extend.userName"></span>
        </el-form-item>
        <el-form-item label="学校：" prop="extend.schoolName">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="手机号：" prop="extend.mobile">
          <span v-text="details.extend.mobile"></span>
        </el-form-item>
        <el-form-item label="提交时间：" prop="addTime">
          <span v-text="details.addTime"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 处理 -->
    <el-dialog title="处理方式" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item prop="status" label="处理方式：">
          <el-radio-group v-model="form.status">
            <el-radio label="0">待处理</el-radio>
            <el-radio label="1">已处理</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="submit">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'creative',
  components: {
    PagedTable,
    AreaTree
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      aVisible:false,
      moduleOptions:[],
      typeOptions:[],
      checkVisible: false,
      queryParams: {
        pageNum:1,
        pageSize:10
      },
      form:{
        id:'',
        status:'',
      },
      formRules:{
        status:[{ required: true, message: '', trigger: 'blur' }]
      },
      details:{
        extend: {},
        id: '',
        schoolId: '',
        userId: '',
        module: '',
        ideaType: '',
        title: '',
        content: '',
        srcType: null,
        status: '',
        addTime: '',
        imgList: []
      },
      
      pageApi:'ideaList',
      loadApi: "ideaLoad",
      deleteApi:'ideaDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("creative", ["creativeList", "creativeTotal"]),
  },
  methods: {
    ...mapActions("creative",["ideaList", "ideaLoad", "ideaFinish", "ideaDelete","dictSelectAll"]),
    async dispose(id) {
      this.aVisible = true
      this.form.id = id
    },
    async submit() {
      let params = Object.assign({}, this.form)
      this.$refs.formFileds.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            this.bLoading = true
            try {
              await this.ideaFinish(params)
              this.$message.success('数据保存成功！')
              this.aVisible = false
              this.refresh()
            }catch(e){
              log(e)
            }finally {
              this.bLoading = false
            }
          })
        }
      })
    },
    handleQuery(data) {
        this.clearArea(false)
        data.forEach(ele => {
          if ((ele + "").length === 9) { this.queryParams.province = ele }
          if ((ele + "").length === 12) { this.queryParams.city = ele }
          if ((ele + "").length === 15) { this.queryParams.area = ele }
          if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
        });
        this.refresh();
      },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  async mounted() {
    log(this.details)
    // this.schoolType = await this.getSchoolCombo({schoolName:''})
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    this.moduleOptions = await this.dictSelectAll({pid:106001001}) 
    this.typeOptions = await this.dictSelectAll({pid:106001002}) 
    
  }
};
</script>
<style lang="scss" scoped>
.w450 { width: 450px; }
.inlineForm { 
  .el-image { 
    width: 200px; 
  } 
}
.select{
  .year {
    /deep/.el-input__inner {  
      background: url('../../assets/images/he/operate/area.svg') no-repeat; 
      background-size: 12px 14px;  
      background-position: 8px 7px; 
      padding: 0 0 0 30px; 
      box-sizing: border-box;
      font-size: 14px;
    }
  }
}
</style>